    import React from "react"
    import PropTypes from "prop-types"
    import { StaticQuery, graphql } from "gatsby"
    import favicon from '../images/favicon.ico'
    import Helmet from 'react-helmet'
//    import Header from "./header"
//    import "./layout.css"
    
    const Layout = ({ children }) => (
      <div>
<Helmet>
  <link rel="icon" href={favicon} />
</Helmet>
<StaticQuery
  query={graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `}
  render={data => (
    <>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Pokey
        </footer>
      </div>
    </>
  )}
/>
       </div>
     )
    
    Layout.propTypes = {
      children: PropTypes.node.isRequired,
    }
    
export default Layout
